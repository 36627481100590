$default: #007bff;
$default_dark: #0562c7;

$success: #28a745;
$success_dark: #1f8838;

$danger: #dc3545;
$danger_dark: #bd1120;

$info: #17a2b8;
$info_dark: #138b9e;

$warning: #eab000;
$warning_dark: #ce9c09;

$awesome: #685dc3;
$awesome_dark: #4c3fb1;

$default_timer: #007bff;
$default_timer_filler: #fff;

$success_timer: #28a745;
$success_timer_filler: #fff;

$danger_timer: #dc3545;
$danger_timer_filler: #fff;

$info_timer: #17a2b8;
$info_timer_filler: #fff;

$warning_timer: #eab000;
$warning_timer_filler: #fff;

$awesome_timer: #685dc3;
$awesome_timer_filler: #fff;

.notification-default {
  background-color: $default;
  border-left: 8px solid $default_dark;

  .timer {
    background-color: $default_timer;
  }
  .timer-filler {
    background-color: $default_timer_filler;
  }
  .notification-close {
    background-color: $default;
  }
}

.notification-success {
  background-color: $success;
  border-left: 8px solid $success_dark;

  .timer {
    background-color: $success_timer;
  }
  .timer-filler {
    background-color: $success_timer_filler;
  }
  .notification-close {
    background-color: $success;
  }
}

.notification-danger {
  background-color: $danger;
  border-left: 8px solid $danger_dark;

  .timer {
    background-color: $danger_timer;
  }
  .timer-filler {
    background-color: $danger_timer_filler;
  }
  .notification-close {
    background-color: $danger;
  }
}

.notification-info {
  background-color: $info;
  border-left: 8px solid $info_dark;

  .timer {
    background-color: $info_timer;
  }
  .timer-filler {
    background-color: $info_timer_filler;
  }
  .notification-close {
    background-color: $info;
  }
}

.notification-warning {
  background-color: $warning;
  border-left: 8px solid $warning_dark;

  .timer {
    background-color: $warning_timer;
  }
  .timer-filler {
    background-color: $warning_timer_filler;
  }
  .notification-close {
    background-color: $warning;
  }
}

.notification-awesome {
  background-color: $awesome;
  border-left: 8px solid $awesome_dark;

  .timer {
    background-color: $awesome_timer;
  }
  .timer-filler {
    background-color: $awesome_timer_filler;
  }
  .notification-close {
    background-color: $awesome;
  }
}

.notification-container-top-center,
.notification-container-bottom-center,
.notification-container-top-left,
.notification-container-top-right,
.notification-container-bottom-left,
.notification-container-bottom-right,
.notification-container-mobile-top,
.notification-container-mobile-bottom {
  position: fixed;
  z-index: 8000;
}

.notification-container-top-center,
.notification-container-bottom-center,
.notification-container-top-right,
.notification-container-bottom-left,
.notification-container-bottom-right {
  width: 325px;
}

.notification-container-top-center {
  transform: translateX(-50%);
  top: 20px;
  left: 50%;
}

.notification-container-bottom-center {
  transform: translateX(-50%);
  bottom: 20px;
  left: 50%;
}

.notification-container-top-left {
  left: 20px;
  top: 20px;
}

.notification-container-top-right {
  right: 20px;
  top: 20px;
}

.notification-container-bottom-left {
  left: 20px;
  bottom: 20px;
}

.notification-container-bottom-right {
  bottom: 20px;
  right: 20px;
}

.notification-container-mobile-top {
  right: 20px;
  left: 20px;
  top: 20px;
}

.notification-container-mobile-bottom {
  right: 20px;
  left: 20px;
  bottom: 20px;
  margin-bottom: -15px;
}

@keyframes timer {
0% { width: 100%; }
100% { width: 0%; }
}

.notification-item {
display: flex;
position: relative;
border-radius: 3px;
margin-bottom: 15px;
box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
cursor: pointer;

.timer {
    width: 100%;
    height: 3px;
    margin-top: 10px;
    border-radius: 5px;

    .timer-filler {
    height: 3px;
    border-radius: 5px;
    }
}

.notification-title {
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.notification-message {
    color: #fff;
    max-width: calc(100% - 15px);
    font-size: 14px;
    line-height: 150%;
    word-wrap: break-word;
    margin-bottom: 0;
    margin-top: 0;
}

.notification-content {
    padding: 8px 15px;
    display: inline-block;
    width: 100%;
}

.notification-close {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 10px;

    &::after {
    content: '\D7';
    position: absolute;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 12px;
    left: 50%;
    top: 50%;
    }
}
}

.n-parent { 
width: 275px;
}

.notification-container-mobile-top .notification-item,
.notification-container-mobile-bottom .notification-item,
.notification-container-mobile-top .n-parent,
.notification-container-mobile-bottom .n-parent {
max-width: 100%;
width: 100%;
}

.notification-container-top-right .n-parent,
.notification-container-bottom-right .n-parent {
margin-left: auto;
}

.notification-container-top-left .n-parent,
.notification-container-bottom-left .n-parent {
margin-right: auto;
}

.notification-container-mobile-top .n-parent,
.notification-container-mobile-bottom .n-parent {
margin-left: auto;
margin-right: auto;
}