//				 .IIIIIIIIIIIII.
//			 .IIIIIIIIIIIIIIIIIIIII.
//		  .IIIIIIIIIIIIIIIIIIIIIIIIIII.
//	    .IIIIIIIIIIIIIIIIIIIIIIIIIIIIIII.
//	   .IIIIIIIII            .IIIIIIIIIII.
//	 .IIIIIIIIIII   .IIIIIIIIIIIIIIIIIIIIII.
//	.IIIIIIIIIIII  .  .IIIIIIIIIIIIIIIIIIIII.
//	.IIIIIIIIIIII  .I.  .IIIIIIIIIIIIIIIIIII.
//	.IIIIIIIIIIII  .IIII.  .IIIIIIIIIIIIIIII.
//	 .IIIIIIIIIII .IIIIIII.  .IIIIIIIIIIIII.
//	  .IIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIII.
//	    .IIIIIIIIIIIIIIIIIIIIIIIIIIIIIIII.
//		  .IIIIIIIIIIIIIIIIIIIIIIIIIII.
//			 .IIIIIIIIIIIIIIIIIIIII.
//				 .IIIIIIIIIIIII.

// 01000011 01110101 01110010 01110011 01101111 01110010
// Cursor Styles
// HUB.IEMA V0.01


// Basic

a, h1, h2, h3, h4, h5, h6, p, li, input, td {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: greyscale;
}

.roboto {
    font-family: 'Roboto', sans-serif;
}

body {
    overflow: hidden;
}

button, a {
    @include transition(all 0.1s ease-in-out);
    &:hover, &:focus {
        outline: 0;
        text-decoration: none;
    }
}

.page-title {
    margin-bottom: 1.2em;
}

.global-version {
    color: $gray-600;
}

// buttons
.btn-light {
    background: $white;
    border: solid 1px $gray-400;
    color: $gray-700;
}

.btn-plus {
    &::before {
        content: " ";
        display: inline-block;
        height: 17px;
        width: 12px;
        background-size: 11px;
        margin-right: 8px;
        background-position: 0 3px;
        vertical-align: middle;
        background-image: url(/assets/icons/icon-plus.svg);
        background-repeat: no-repeat;
    }
}

button > * {
    pointer-events: none;
}

// Custom checkbox
// .custom-control-label, .custom-control.custom-switch {
//     cursor: pointer;
// }
.custom-control-input:checked ~ .custom-control-label::before {
    background-repeat: no-repeat;    
    background-image: url(/assets/icons/checkbox.svg);
    background-size: 14px;
    background-position: center center;
    cursor: pointer;
}

// Add an asterisk after required form field labels
form label.required:after {
    color: red;
    content: " *";
}

.form-check {
    margin-bottom: 8px;
}

.member-address select {
    padding: 10px; 
    height: 39px !important;
    // borderfe: $border-width
}

.table-borderless {
    border: none;
    td, th { border: none; }
}

.table-row-border {
    border: 1px solid $gray-300
}

.table-row-border-top {
    border-top: 1px solid $gray-300
}

.table-date {
    background: $primary;
    color: #FFF;

    > td {
        background: $primary;
        padding: 0.95rem 0.75rem;
        font-size: 1.1em;    
    }
}

table tbody tr.table-row-totals {
    background-color: #FFF !important;
}

// Custom Select Box Padding
select.form-control {
    padding: 0.5px 0.75rem;
}

/**
 * Bg colours
 * ====================
 */
 .bg-light-gray {
    background-color: $gray-200;
 }

 .bg-body {
    background-color: $body-bg;
 }

/**
 * Custom buttons.
 * ====================
 */
.btn-view {
    span {
        &:before {
            content: '';
            width: 13px;
            height: 10px;
            background-image: url('/assets/icons/icon-view.svg');
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            display: inline-block;
            margin-right: 5px;
        }
    }
}

.btn-search {
    span {
        &:before {
            content: '';
            width: 13px;
            height: 13px;
            background-image: url('/assets/icons/icon-search.svg');
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            display: inline-block;
            margin-right: 5px;
        }
    }
}

.btn-edit {
    span {
        &:before {
            content: '';
            width: 13px;
            height: 10px;
            background-image: url('/assets/icons/icon-pen.svg');
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            display: inline-block;
            margin-right: 5px;
        }
    }
}

.btn-delete {
    span {
        &:before {
            content: '';
            width: 13px;
            height: 10px;
            background-image: url('/assets/icons/icon-bin.svg');
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            display: inline-block;
            margin-right: 5px;
        }
    }
}

.btn-profile {
    span {
        &:before {
            content: '';
            width: 20px;
            height: 20px;
            background-image: url('/assets/icons/icon-profile.svg');
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            display: inline-block;
            margin-right: 5px;
            vertical-align: -5px;
        }
    }
}

/**
 * Card Panels.
 * ====================
 */
.card-header {
    background-color: transparent;
    border-bottom: none;
    // padding: 1.25rem;
    padding-bottom: 0;
}


/**
 * Header.
 * ====================
 */
.header {
    // box-shadow: (0 1px 4px rgba(0, 0, 0, .3));
    height: 61px;
    position: relative;
    z-index: 1;
    background-color: $primary;

    .search {
        margin-bottom: 0px !important;
        width: 100%;
        height: 32px;
    }

    .user-section {
        img {
            height: 40px;
        }
    }

    .myaccount-button {
        color: #FFF;
        display: inline-block;
        height: 17px;
        margin: 20px 19px;
        font-size: 1em;
        font-weight: 400;
    }
    
    .logout-button {
        background-image: url(/assets/icons/icon-logout.svg);
        background-size: 16px;
        background-color: transparent;
        z-index: 1;
        position: relative;
        background-repeat: no-repeat;
        background-position: center center;
        height: 30px;
        width: 30px;
        cursor: pointer;
        margin: 15px 0 16px;
        border: none;

        &:hover {
            opacity: .8;
        }
    }
}


// main section
$main-height: 100vh;

.main-section {
    height: $main-height;
    
    .main-content {
        background-color: #FFF;
        overflow-y: scroll;
        height: $main-height;
        margin-left: 250px
    }
}



/**
 * Breadcrumbs.
 * ====================
 */
.breadcrumb {
    padding: 0.2rem 0 0.6em !important;
    font-size: $font-size-base;
    color: $body-color;
    @extend .py-4;
    @extend .mb-0;
    opacity: .5;

    .breadcrumb-item + .breadcrumb-item::before {
        content: "/";
    }

    a {
        color: $body-color;

        &:hover, &:focus {
            color: $black;
        }
    }
}

/**
 * Table.
 * ====================
 */
table {
    border: 1px solid $border-color;
    @extend .mb-0;

    thead, 
    tbody {
        th, 
        td {
            vertical-align: middle !important;
        }

        // Sort headers.
        th {
            cursor: pointer;
            
            span.sort {
                display: inline-block;
                height: 20px;
                width: 9px;
                background-position: top center;
                background-size: 9px;
                background-repeat: no-repeat;
                vertical-align: middle;
                margin-left: 7px;

                &.sort-desc {
                    background-image: url(/assets/sort-desc.svg);
                }
                &.sort-asc {
                    background-image: url(/assets/sort-asc.svg);
                }
                &.sort-none {
                    background-image: url(/assets/sort-none.svg);
                }
            }
        }
    }


    &.table-invoices {
        border-left: 1px solid $gray-300;

        table {
            background-color: none;
        }

        thead th {
            border: 0;
        }

        .product-items {
            border: none;
            padding: 0;

            table {
                box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.3);
                border: 0;
            }
        }
    }

}

// Tabs
.tab-list {  border-bottom: 1px solid #ccc;
    padding-left: 0;
}

.tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;

    &:hover {
        cursor:pointer;
    }
}

.tab-list-active {
    background-color: white;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
}


// side nav
.side-nav {
    height: 100vh;
    position: fixed;
    left: 0;
    width: 250px;
    // border-right: 1px solid $border-color;

    .logo {
        padding: 18px 25px;
        height: 61px;
    }

    > ul {
        li {
            > a, 
            > .submenu-item a {
                padding: 14px 15px 14px 52px;
                display: block;
                // color: $body-color;
                color: $black;
                background-repeat: no-repeat;
                background-position: left 20px center;
                background-size: 15px;
            }
            opacity: 1;
            
            &:hover, 
            &:focus {
                background-color: $white;
                opacity: 1;
            }
            
            &.active {
                background-color: $white;
                opacity: 1;
            }

            .submenu {
                border-top: 1px solid $body-bg;
                li {
                    a {
                        padding-left: 30px;
                        background-color: #FFF;
                    }
                }
            }

            &.submenu-toggle {
                > .submenu-item {
                    position: relative;
                }

                .submenu-toggler {
                    width: 46px;
                    height: 46px;
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: transparent;
                    border: 0px solid transparent;
                    @include transition(all 0.3s ease-in-out);

                    &:hover {
                        opacity: .7;
                    }
                    
                }
                
                li.active {
                    opacity: .7;
                }
            }



            // Icons for side nav
            &.home > a { background-image: url('/assets/icons/icon-home-black.svg'); }
            &.user > a { background-image: url('/assets/icons/icon-user-black.svg'); }
            &.globe > a { background-image: url('/assets/icons/icon-globe-black.svg'); }
            &.applications > a, &.applications > .submenu-item > a { background-image: url('/assets/icons/icon-applications.svg'); }
            &.invoices > a { background-image: url('/assets/icons/icon-invoices.svg'); }
            &.payments > a, &.payments > .submenu-item > a { background-image: url('/assets/icons/icon-payments.svg'); background-size: 18px; }
            &.direct-debits > a, &.direct-debits > .submenu-item > a { background-image: url('/assets/icons/icon-direct-debits.svg'); }
            &.users > a { background-image: url('/assets/icons/icon-users.svg'); background-size: 18px; }
            &.emails > a { background-image: url('/assets/icons/icon-email-black.svg'); background-size: 18px; }
            &.members > a { background-image: url('/assets/icons/icon-members.svg'); background-size: 18px; }
            &.settings > a { background-image: url('/assets/icons/icon-settings.svg'); background-size: 18px; }
        }
    }

    .contact-detail {
        font-size: $font-size-base * 0.82;
        // border-top: 1px solid $border-color;
        // border-bottom: 1px solid $border-color;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        @extend .pt-3;

        .contact-icon {
            padding: 10px;
            @extend .btn-primary;
            @include border-radius(50%);
            @include transition(all 0.3s ease-in-out);

            a {
                width: 20px;
                height: 20px;
                padding: 10px;
                display: block;
                background-size: contain;
                background-position: center;
            }
            
            .phone {
                background-image: url('/assets/icons/icon-phone.svg');
            }

            .email {
                background-image: url('/assets/icons/icon-email.svg');
            }
        }
    }
}

.main {
    padding: 35px;
    margin-bottom: 100px;
}


.json-string {
    height: 500px;
    overflow-y: scroll;
    background-color: $gray-100;
    border: solid 2px $body-bg;
}

// Membership Title Header
.membership-header-type {
    color: $gray-900;
    padding: 8px; 
    border: 2px solid $gray-600;
    border-radius: 10px;
}

// login
.login-wrapper {
    height: 100vh;
    background-image: url('/assets/photos/login-bg.png');
    background-color: $gray-300;
    background-size: cover;
    background-repeat: no-repeat;
    @extend .position-relative;

    .login-section {
        text-align: center;
        vertical-align: middle;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 500px;
        width: 80%;

        .company-logo {
            img {
                height: 40px;
            }
        }

        .login-form {
            background-color: $white;
            padding: 35px 25px 35px 25px;

            .form-control {
                border: none;
                border-bottom: 1px solid $gray-200;
                @include border-radius(0px);
            }
        }
    }
}

// form
.form-wrapper {
    max-width: 70%;
}

form {
    .form-control {
        // @extend .mb-3;
        // background-color: rgba(0, 0, 0, 0.05);
    }
    .react-datepicker-wrapper {
        display: block;
        width: 100%;
    }

    textarea {
        resize: none;
    }

    .selectpicker {
        background-color: $white;
    }
}


/**
 * Page not found.
 * =======================
 */ 
#page-not-found {
    position: relative;
    > div {
        background: rgba(0, 0, 0, .1);
        display: inline-block;
        padding: 20px 40px;
        position: fixed;
        text-align: center;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        h1 {
            font-size: 3.7rem;
        }
    }
}


/**
 * Home page.
 * =======================
 */ 
 .dash-board {
    text-align: center;
    border-radius: $border-radius;
    @extend .bg-body;
    @extend .mb-5;
    
    button {
        @extend .mt-5;
    }
 }



/**
 * Members page.
 * =======================
 */ 
ul.nav.nav-tabs {
    border: none;
    border-bottom: 1px solid $gray-400;

    button {
        border: none;
        border-bottom: 3px solid $primary;
        margin-bottom: 0px;
        border-color: $primary !important;
        cursor: pointer;
        padding: 6px 7px;
        margin-right: 10px;
        
        &:not(.active) {
            border-bottom: 3px solid transparent !important;
            background-color: transparent;
        }
    }
    + .card {
        border-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

// Loading in table.
.table-data {
    min-height: 400px;
}
.loading-table-data {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    transition: all .3s ease;
    z-index: -1;
    opacity: 0;
    pointer-events: none;

    > div {
        position: absolute;
        display: block;
        text-align: center;
        width: 100%;
        top: 50%;
        left: 0;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.6);
        -webkit-transform: translateY(-52%);
        transform: translateY(-52%);
        transition: all .3s cubic-bezier(.25, .46, .45, .94)
    }
    

    &.active {
        opacity: 1;
        z-index: 2;
        pointer-events: all;
    
        > div {
            -webkit-transform: translateY(50%);
            transform: translateY(50%)
        }
    }
}


/**
 * Member detail page.
 * =======================
 */

 // Used on the invoices tab
 .details-link {
    border: none;
    padding: 0;
    padding-left: 4px;
    color: $black;
    text-decoration: underline;
}

.tooltip-info:after {
    content:url('/assets/icons/tooltip.svg'); 
    background-size: 18px;
    background-repeat: no-repeat;
    height: 20px;
    width: 19px;
    border: none;
}

/**
 * Custom icons.
 * =======================
 */
.icon-enabled, .icon-disabled {
    display: inline-block;
    height: 12px;
    width: 12px;
    background: $success;
    border-radius: 10px;
    border: 1px solid $gray-300;
}
.icon-disabled {
    background: $danger;
}


/**
 * Collapsibles.
 * =======================
 */
[collapsible-handler] {
    background-image: url(/assets/icons/expand-icon.svg);
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 9px;
    border: none;
    margin-right: 10px;
    cursor: pointer;
    transition: transform 0.2s ease-out;

    &.expanded {
        transform: rotate(90deg);
    }
}
[collapsible] {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}




/**
 * Invoice detail page.
 * =======================
 */
.view-invoice {
    margin-right: 10px; 
}


.select-address {    
	input {
		display: none;
		+ div {
            display: block;
            width: 100%;

            > label {
                border: 1px solid $gray-500;
                color: $gray-700;
                padding: 10px;
                padding-left: 35px;
                display: block;
                cursor: pointer;
                min-width: 100%;
                display: block;
                margin: 15px 0;
            }
		}
		
		&:checked + div > label {
			border: 1px solid $gray-900;
			color: $gray-900;
			background-image: url(/assets/icons/tick-gray.svg);
			background-repeat: no-repeat;
            background-position: top 10px left 5px;
			background-size: 22px;
		}
	}
}



/**
 * Email Log Styling
 * =======================
 */ 

.email-log-datepicker {
    margin-top: 5px; 
    margin-right: 5px; 
}

.email-log-message {
    .btn, .btn-primary {
        table {
            border: none;
            * {
                color: white !important; 
            }
        }
    }
}

/**
 * Fullpage spinner
 * =======================
 */ 
#fullpage-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: $gray-100;
    z-index: 1000;

    .loader-3 {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -25px;
        display: block;

        .dot {
            width: 10px;
            height: 10px;
            background: $brand-grape;
            border-radius: 50%;
            position: absolute;
            top: calc(50% - 5px);
        }
        
        .dot1 {
            left: 0px;
            -webkit-animation: dot-jump 0.5s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
            animation: dot-jump 0.5s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
        }
        
        .dot2 {
            left: 20px;
            -webkit-animation: dot-jump 0.5s 0.2s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
            animation: dot-jump 0.5s 0.2s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
        }
        
        .dot3 {
            left: 40px;
            -webkit-animation: dot-jump 0.5s 0.4s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
            animation: dot-jump 0.5s 0.4s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
        }
    }

    @-webkit-keyframes dot-jump {
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
        100% {
            -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
        }
    }
    
    @keyframes dot-jump {
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
        100% {
            -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
        }
    }
}

/**
 * Filter statuses.
 */
.form-group.form-group-filter-status {
    display: inline-block;
    margin-top: 10px;

    .badge:not(.active) {
        opacity: .6;
    }

    .badge {
        cursor: pointer;
        border-radius: 0.15rem;
        border: none;
        margin-right: 5px;
        padding: 0.45em 0.6em;
    }
}


.react-datepicker__day--selected, .react-datepicker__day--today, .react-datepicker__day--highlighted {
    background-color: $brand-grape !important;
}
